*{
    margin: 0px;
    padding: 0px;
    font-family: Roboto, sans-serif;
}

.container-form-login{
    display: flex;
    height: 100vh;
    flex-direction: column;
    background-color: #2B334F;
}

.container-titre-portail{
    display: flex;
    flex-direction: column;
    height: 40%;
    justify-content: center;
    align-items: center;
    color: white;
}

.container-titre-portail img{
    height: 100px;
}

.container-form-portail {
    display: flex;
    justify-content: center;
    height: 50%;
}

.form-portail{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.container-form-portail button{
    background-color: #EE7F12;
    border-radius: 15px;
    width: 50%;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    padding: 8px;
    transition: color 100ms;
    touch-action: manipulation;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
  
.container-form-portail button:hover,
.container-form-portail button:focus {
    background-color: #d5720e;
}

.footer-portail{
    display: flex;
    height: 10%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container-copyright-portail-footer {
    color: white;
    font-size: 14px;
}

.container-thomas-portail-footer a{
    color: white;
    font-size: 14px;
    text-decoration: underline;
}

#email-form-portail {
    height: 10%;
    width: 100%;
    padding: 12px;
    outline: none;
    border: none;
    border-radius: 15px;
    font-size: 16px;
    color: rgba(15, 15, 51, 0.8);
    background-color: #ffffff;
}

#mdp-form-portail {
    height: 10%;
    width: 100%;
    padding: 12px;
    outline: none;
    border: none;
    border-radius: 15px;
    font-size: 16px;
    color: rgba(15, 15, 51, 0.8);
    background-color: #ffffff;
}