*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
}

/* ----- BANNIERE ----- */

.banner-container{
    display: flex;
    height: 93vh;
    width: 100%;
    background-image: url("../../public/img/banniere/banniere.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
}

.titre-banner{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;    
}

.titre-banner h1 {
    color: white;
    display: flex;
    background-color: #2B334F;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    font-size: 32px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.button-banner{
    display: flex;
    justify-content: center;
    height: 20%;
}

.container-button{
    display: flex;
    height: 21%;
}

.button-banner button{
  background-color: #EE7F12;
  border-radius: 15px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  line-height: 20px;
  outline: none;
  padding: 15px 50px;
  transition: color 100ms;
  touch-action: manipulation;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.button-banner button:hover,
.button-banner button:focus {
  background-color: #d5720e;
}




.partenaires-banner{
    display: flex;
    width: 100%;
    justify-content: space-around;
    height: 30%;
    align-items: center;
}

#partenaire-1 {
    display: flex;
    height: 40%;
    width: 15%;
    background-image: url("../../public/img/banniere/partenaire-1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

#partenaire-2 {
    display: flex;
    height: 40%;
    width: 15%;
    background-image: url("../../public/img/banniere/partenaire-2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

#partenaire-3 {
    display: flex;
    height: 40%;
    width: 15%;
    background-image: url("../../public/img/banniere/partenaire-3.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}


/* -------------------- */


.container-info-accueil{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 700px;
    background-color: #ECECEC;
}



.accueil-dernier-resultats {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 400px;
    height: 620px;
}

.accueil-derniere-actualite {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 400px;
    height: 620px;
}

.accueil-prochains-matchs {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 400px;
    height: 620px;
}

.titre-accueil-dernier-resultats {
    display: flex;
    justify-content: center;
}

.titre-accueil-dernier-resultats h2 {
    color: white;
    background-color: #2B334F;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.scorenco-accueil-dernier-resultats{
    display: flex;
    width: 400px;
    height: 500px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.titre-accueil-prochains-matchs {
    display: flex;
    justify-content: center;
}

.titre-accueil-prochains-matchs h2 {
    color: white;
    background-color: #2B334F;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.scorenco-accueil-prochains-matchs{
    display: flex;
    width: 400px;
    height: 500px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.container-photos {
    display: flex;
    height: 780px;
    justify-content: center;
    align-items: center;
    background-color: #ECECEC;
    flex-wrap: nowrap;
}

.accueil-derniere-photo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 960px;
    height: 700px;
}

.titre-accueil-derniere-photo {
    display: flex;
    justify-content: center;
}

.titre-accueil-derniere-photo h2 {
    color: white;
    background-color: #2B334F;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.image-accueil-derniere-photo{
    display: flex;
    border: 1px solid black;
    background-color: #D9D9D9;
    width: 960px;
    height: 565px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.titre-accueil-derniere-actualite {
    display: flex;
    justify-content: center;
}

.titre-accueil-derniere-actualite h2 {
    color: white;
    background-color: #2B334F;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.container-lastarticle-accueil-prochains-matchs{
    display: flex;
    width: 400px;
    height: 500px;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.lastarticle-accueil-prochains-matchs{
    display: flex;
    height: 80%;
    width: 100%;
}

.container-bouton-lastarticle-accueil-prochains-matchs {
    display: flex;
    height: 20%;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
}

.bouton-lastarticle button{
    background-color: #EE7F12;
    border-radius: 15px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    padding: 10px 30px;
    transition: color 100ms;
    touch-action: manipulation;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
  .bouton-lastarticle button:hover,
  .bouton-lastarticle button:focus {
    background-color: #d5720e;
  }

  


  .container-bouton-lastphoto{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
}

.bouton-lastphotobutton button{
    background-color: #EE7F12;
    border-radius: 15px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    padding: 10px 30px;
    transition: color 100ms;
    touch-action: manipulation;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  
  .bouton-lastphotobutton button:hover,
  .bouton-lastphotobutton button:focus {
    background-color: #d5720e;
  }













































































@media (max-width: 1215px) {
    .container-info-accueil {
        flex-direction: column;
        height: 1900px;
    }
}

@media (max-width: 980px) {
    .image-accueil-derniere-photo {
        width: 640px;
        height: 390px;
    }

    .accueil-derniere-photo {
        width: 640px;
        height: 580px;
    }

    .container-photos {
        height: 600px;
    }
}

@media (max-width: 650px) {
    .image-accueil-derniere-photo {
        width: 480px;
        height: 300px;
    }

    .accueil-derniere-photo {
        width: 480px;
        height: 550px;

    }

    .container-photos {
        height: 720px;
    }

}


@media (max-width: 490px) {
    .image-accueil-derniere-photo {
        width: 380px;
        height: 250px;
    }

    .accueil-derniere-photo {
        width: 384px;
        height: 430px;

    }

    .container-photos {
        height: 440px;
    }

}


@media (max-width: 390px) {
    .image-accueil-derniere-photo {
        width: 316px;
        height: 210px;
    }

    .accueil-derniere-photo {
        width: 320px;
        height: 420px;

    }

    .container-photos {
        height: 430px;
    }

}


@media (max-width: 325px) {
    .image-accueil-derniere-photo {
        width: 272px;
        height: 180px;
    }

    .accueil-derniere-photo {
        width: 274px;
        height: 340px;

    }

    .container-photos {
        height: 360px;
    }

}











@media (max-width: 940px) {
    .titre-banner h1 {
        font-size: 20px;
    }

    .button-banner button {
        font-size: 16px;
        padding: 10px 50px;
    }

    .container-info-accueil {
        flex-direction: column;
        height: 1900px;
    }

    .accueil-dernier-resultats {
        width: 320px;
    }

    .scorenco-accueil-dernier-resultats {
        width: 320px;
    }

    .accueil-prochains-matchs {
        width: 320px;
    }

    .scorenco-accueil-prochains-matchs {
        width: 320px;
    }
}

@media (max-width: 400px) {
    .accueil-prochains-matchs {
        width: 270px;
    }

    .scorenco-accueil-prochains-matchs {
        width: 270px;
    }

    .accueil-derniere-actualite {
        width: 270px;
    }

    .container-lastarticle-accueil-prochains-matchs {
        width: 270px;
    }

    .accueil-dernier-resultats {
        width: 270px;
    }

    .scorenco-accueil-dernier-resultats {
        width: 270px;
    }


}

