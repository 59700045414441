*{
    padding: 0px;
    margin: 0px;
    font-family: Roboto, sans-serif;
}

.container-newsletter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
    background-color: #ECECEC;
}

.newsletter-titre {
    display: flex;
    padding-top: 60px;
    padding-bottom: 80px;
    align-items: flex-start;
}

#newsletter-bouton{
    background-color: #EE7F12;
    text-decoration: none;
    border-radius: 15px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    padding: 10px 30px;
    transition: color 100ms;
    touch-action: manipulation;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
  
#newsletter-bouton:hover,
#newsletter-bouton:focus {
    background-color: #d5720e;
}


.container-article {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #ECECEC;
}

.article-titre {
    display: flex;
    padding: 10px 0px 25px 0px;
}

.container-article h2{
    color: white;
    display: flex;
    background-color: #2B334F;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    font-size: 32px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}