*{
    margin: 0px;
    padding: 0px;
    font-family: Roboto, sans-serif;
}

.container-contactform{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 440px;
    width: 100%;
    background-color: #ECECEC;
}

.contact-form {
    display: flex;
    width: 70%;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.input-form-ct {
    display: flex;
    height: 10%;
    flex-direction: column;
}

.input-form {
    height: 10%;
    width: 100%;
    padding: 12px;
    outline: none;
    border: none;
    border-radius: 15px;
    font-size: 16px;
    color: rgba(15, 15, 51, 0.8);
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.input-form-ct-area {
    display: flex;
    height: 20%;
    flex-direction: column;
}

.input-form-area{
    width: 100%;
    padding: 12px;
    outline: none;
    border: none;
    border-radius: 15px;
    font-size: 16px;
    color: rgba(15, 15, 51, 0.8);
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.bouton-form-contact{
    display: flex;
    background-color: #EE7F12;
    border-radius: 15px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    line-height: 20px;
    outline: none;
    padding: 7px 15px;
    transition: color 100ms;
    touch-action: manipulation;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
  
.bouton-form-contact:hover,
.bouton-form-contact:focus {
    background-color: #d5720e;
}