*{
    margin: 0px;
    padding: 0px;
}

.container-card{
    display: flex;
    height: 390px;
    margin: 20px 0px 20px 0px;
    border: 1px solid black;
    background-color: #D9D9D9;
    border-radius: 5px;
}

.image-media {
    height: 360px;
    width: 640px;
}

.date-photos {
    font-style: italic;
}


@media (max-width: 645px) {
    .image-media{
        height: 270px;
        width: 480px;
    }

    .container-card {
        height: 300px;
    }

}

@media (max-width: 485px) {
    .image-media{
        height: 216px;
        width: 384px;
    }

    .container-card {
        height: 250px;
    }

}

@media (max-width: 390px) {
    .image-media{
        height: 180px;
        width: 320px;
    }

    .container-card {
        height: 210px;
    }

}

@media (max-width: 330px) {
    .image-media{
        height: 154px;
        width: 274px;
    }

    .container-card {
        height: 180px;
    }

}