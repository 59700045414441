*{
  margin: 0px;
  padding: 0px;
  scroll-behavior: smooth;
}


    ::-webkit-scrollbar {
    width: 12px;
    background: #e0e0e0;
  }

  ::-webkit-scrollbar-track { 
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #1c1a19;  
  }
  ::-webkit-scrollbar-thumb:window-inactive {
  background: #1c1a19; 
  }