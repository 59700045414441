*, ::before, ::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.navbar {
    background-color: #2B334F;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: Roboto, sans-serif;
    height: 80px;
 }
 
 .nav-list {
    list-style-type: none;
    
 }
 
 .nav-list .list-item {
    display: inline-block;
    padding: 25px;
 }
 
 .list-item a {
    text-decoration: none;
    color: #ffffff;
    font-size: 20px;
   display: inline-block;
   position: relative;
   color: #ffffff;
 }

 .list-item a::after {
   content: '';
   position: absolute;
   width: 100%;
   transform: scaleX(0);
   height: 2px;
   bottom: 0;
   left: 0;
   background-color: #ffffff;
   transform-origin: bottom right;
   transition: transform 0.25s ease-out;
 }

 .list-item a:hover::after {
   transform: scaleX(1);
   transform-origin: bottom left;
 }
 
 .navbar .logo-nav img{
    width: 100px;
    padding-top: 120px;
    text-decoration: none;
 }

 /*----------------------*/

 .navbar .toggle-btn {
    color: #ffffff;
    font-size: 1.5rem;
    cursor: pointer;
    display: none;
 }

 .dropdown-menu {
    display: none;
    right: 2rem;
    top: 60px;
    height: 0;
    width: 100%;
    background-color: #2b334fcc;
    overflow: hidden;
    transition: height .2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
 }

 .dropdown-menu.open {
    height: 330px;
 }

 .dropdown-menu li {
    padding: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
 }


 #icon-menu-b{
    width: 30px;
 }

 @media (max-width: 1017px) {
    .navbar {
        justify-content: space-between;
    }

    .navbar .nav-list{
        display: none;
    }

    .navbar .toggle-btn {
        display: block;
        padding-right: 7%;
    }

    .navbar .logo-nav {
        padding-left: 7%;
    }

    .navbar .logo-nav img{
        width: 32px;
        padding-top: 0px;
        color: #F5CB5C;
        text-decoration: none;
     }

    .dropdown-menu {
        display: block;
    }
}



