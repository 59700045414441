* {
    padding: 0px;
    margin: 0px;
}

.container-image-ecole {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.image-ecole {
    display: flex;
    width: 630px;
    height: 360px;
    border: 1px solid black;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.image-ecole-1-2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 400px;
    width: 100%;
}

#ecole-1 {
    background-image: url("../../../public/img/equipes/1.jpg");
}

#ecole-2 {
    background-image: url("../../../public/img/equipes/2.jpg");
}

.image-ecole-3-4 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 400px;
    width: 100%;
}

#ecole-3 {
    background-image: url("../../../public/img/equipes/3.jpg");
}

#ecole-4 {
    background-image: url("../../../public/img/equipes/4.jpg");
}

.image-ecole-5-6 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 400px;
    width: 100%;
}

#ecole-5 {
    background-image: url("../../../public/img/equipes/5.jpg");
}

#ecole-6 {
    background-image: url("../../../public/img/equipes/6.jpg");
}

.image-ecole-7-8 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 400px;
    width: 100%;
}

#ecole-7 {
    background-image: url("../../../public/img/equipes/7.jpg");
}

#ecole-8 {
    background-image: url("../../../public/img/equipes/8.jpg");
}

.image-ecole-9 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 100%;
}

#ecole-9 {
    background-image: url("../../../public/img/equipes/9.jpg");
}