*{
    padding: 0px;
    margin: 0px;
    font-family: Roboto, sans-serif;
}

.container-equipes {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ECECEC;
}

.equipes-titre {
    display: flex;
    justify-content: center;
    padding: 60px 0px 15px 0px;
}

.container-select-equipes{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.container-equipes h2 {
    color: white;
    display: flex;
    background-color: #2B334F;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    font-size: 32px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}