*{
    margin: 0px;
    padding: 0px;
    font-family: Roboto, sans-serif;
}

.container-footer {
    display: flex;
    flex-direction: column;
}

.footer-top{
    display: flex;
    justify-content: center;
    height: 70px;
    width: 100%;
    background-color: #D9D9D9;
}

.adresse-footer {
    display: flex;
    width: 33%;
    border-right: 1px solid black;
    height: 100%;
}

.container-img-adresse-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.img-adresse-footer {
    display: flex;
    height: 30px;
    width: 30px;
    background-image: url("../../public/img/ping.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.text-adresse-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.telephone-footer {
    display: flex;
    width: 34%;
    height: 100%;
}

.container-img-telephone-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.img-telephone-footer {
    display: flex;
    height: 30px;
    width: 30px;
    background-image: url("../../public/img/telephone.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.text-telephone-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
}

.email-footer {
    display: flex;
    width: 33%;
    height: 100%;
    border-left: 1px solid black;
}

.container-img-email-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.img-email-footer {
    display: flex;
    height: 30px;
    width: 30px;
    background-image: url("../../public/img/email.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.text-email-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
}

.footer-bottom {
    display: flex;
    height: 190px;
    background-color: #2B334F;
}

.container-partenaire-footer{
    display: flex;
    flex-direction: column;
    width: 20%;
}

.container-partenaire-1-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33%;
    width: 100%;
}

.partenaire-1-footer {
    display: flex;
    justify-content: center;
    height: 70%;
    width: 70%;
    background-image: url("../../public/img/banniere/partenaire-1.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.container-partenaire-2-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34%;
    width: 100%;
}

.partenaire-2-footer {
    display: flex;
    height: 70%;
    width: 70%;
    background-image: url("../../public/img/banniere/partenaire-2.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.container-partenaire-3-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33%;
    width: 100%;
}

.partenaire-3-footer {
    display: flex;
    height: 70%;
    width: 70%;
    background-image: url("../../public/img/banniere/partenaire-3.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.container-info-footer{
    display: flex;
    width: 20%;
    flex-direction: column;
    font-size: 13px;
}

.loi-info-footer {
    display: flex;
    height: 50%;
    flex-direction: row;
}

.cdt-util-info-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
}

.loi-info-footer a{
    text-decoration: none;
    color: white;
}

.cdt-men-leg-footer {
    display: flex;
    justify-content: center;
    color: white;
    align-items: center;
    width: 50%;
    height: 100%;
}

.titre-info-footer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50%;
    width: 100%;
}

.titre-info-footer h2 {
    font-size: 18px;
    color: white;
}

.container-logo-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.logo-footer img{
    display: flex;
    width: 80px;
    height: 110px;
}

.container-item-footer {
    display: flex;
    flex-direction: column;
    width: 20%;
}

.container-bouton-item-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
}

.container-bouton-item-footer button{
    background-color: #EE7F12;
    border-radius: 15px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    padding: 10px 30px;
    transition: color 100ms;
    touch-action: manipulation;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
  
.container-bouton-item-footer button:hover,
.container-bouton-item-footer button:focus {
    background-color: #d5720e;
}

.container-portail-item-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
}

.container-portail-item-footer button{
    background-color: #000000;
    border-radius: 15px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    outline: none;
    padding: 2px 7px;
    transition: color 100ms;
    touch-action: manipulation;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
  
.container-portail-item-footer button:hover,
.container-portail-item-footer button:focus {
    background-color: #222222;
}

.container-copyright-item-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 15%;
    color: white;
    font-size: 13px;
}

.container-thomas-item-footer{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 15%;
}

.container-thomas-item-footer a{
    text-decoration: underline;
    color: white;
    font-size: 13px;
}

.container-nav-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.nav-list-footer li {
    list-style-type: none;
    padding: 3px 0px 3px 0px;
}

.nav-list-footer a {
    text-decoration: none;
    color: white;
}

.logout-footer{
    display: flex;
    color: white;
    flex-direction: column;
    text-align: center;
}

#bouton-logout-footer{
    background-color: #000000;
    border-radius: 15px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    outline: none;
    padding: 2px 7px;
    transition: color 100ms;
    touch-action: manipulation;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
  
#bouton-logout-footer:hover,
#bouton-logout-footer:focus {
    background-color: #222222;
}



@media (max-width: 1080px) {
    .footer-top {
        flex-direction: column;
        height: 200px;
    }

    .adresse-footer{
        width: 100%;
        border-right: none;
        border-bottom: 1px solid black;
    }

    .telephone-footer{
        width: 100%;
    }

    .email-footer{
        width: 100%;
        border-top: 1px solid black;
        border-left: none;
    }

    .text-email-footer{
        align-items: start;
        width: 70%;
    }

    .container-img-email-footer {
        width: 30%;
    }

    .text-telephone-footer{
        align-items: start;
        width: 70%;
    }

    .container-img-telephone-footer {
        width: 30%;
    }

    .text-adresse-footer{
        align-items: start;
        width: 70%;
    }

    .container-img-adresse-footer {
        width: 30%;
    }

    .footer-bottom {
        flex-direction: column;
        justify-content: center;
        height: 800px;
    }

    .container-partenaire-footer{
        flex-direction: row;
        height: 60%;
        width: 100%;
    }

    .container-partenaire-1-footer {
        height: 100%;
    }

    .container-partenaire-2-footer {
        height: 100%;
    }

    .container-partenaire-3-footer {
        height: 100%;
    }

    .container-info-footer {
        height: 100%;
        width: 100%;
    }

    .titre-info-footer h2 {
        font-size: 20px;
    }

    .container-logo-footer{
        height: 100%;
        width: 100%;
    }

    .container-item-footer{
        height: 100%;
        width: 100%;
    }
    
    .loi-info-footer{
        flex-direction: column;
        align-items: center;
    }

    .container-nav-footer{
        height: 100%;
        width: 100%;
    }

    .container-nav-footer ul{

        padding: 10px 0px 10px 0px;
    }

    .nav-list-footer {
        text-align: center;
    }

    .footer-bottom :nth-child(1) { order: 5; }
    .footer-bottom :nth-child(2) { order: 4; }
    .footer-bottom :nth-child(3) { order: 1; }
    .footer-bottom :nth-child(4) { order: 2; }
    .footer-bottom :nth-child(5) { order: 3; }
}