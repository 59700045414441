*{
    margin: 0px;
    padding: 0px;
    font-family: Roboto, sans-serif;
}

.container-card-articles{
    display: flex;
    text-align: center;
    width: 1000px;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    margin: 20px 0px 20px 0px;
    background-color: #D9D9D9;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.card-articles {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.image-media-articles {
    height: 270px;
    width: 500px;
    border: 1px solid black;
}

.titre-articles {
    font-size: 25px;
    text-align: center;
    padding-bottom: 20px;
    color: #2B334F;
    text-decoration: underline;
}

.titre-articles hr{
    border: 2px solid #2B334F;
    border-radius: 0px;
}

.description-articles {
    font-size: 14px;
    padding: 0px 60px 20px 60px;
    text-align: left;
    white-space: pre-line;
}

.description-articles hr{
    border: 2px solid #2B334F;
    border-radius: 5px;
}

.date-articles {
    font-size: 14px;
    text-align: left;
    font-style: italic;
    padding: 0px 60px 20px 60px;
}


@media (max-width: 1010px) {
    .image-media-articles{
        height: 130px;
        width: 340px;
    }

    .container-card-articles {
        width: 400px;
    }

}

@media (max-width: 430px) {
    .image-media-articles{
        height: 100px;
        width: 290px;
    }

    .container-card-articles {
        width: 350px;
    }
}