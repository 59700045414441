*{
    margin: 0px;
    padding: 0px;
    font-family: Roboto, sans-serif;
}

.container-histo-presentation {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 700px;
    background-color: #ececec;
}

.histo-presentation{
    display: flex;
    flex-direction: column;
    width: 80%;
}

.titre-histo-presentation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
    width: 100%;
}

.titre-histo-presentation h2{
    display: flex;
    height: 25%;
    align-items: center;
    color: white;
    background-color: #2B334F;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    font-size: 32px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.container-box-histo-presentation {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 70%;
    width: 100%;
    background-color: #D9D9D9;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.banner-histo-presentation{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40%;
    background-image: url("../../public/img/stade.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.texte-histo-presentation{
    display: flex;
    align-items: center;
    height: 60%;
    font-size: 16px;
}


.texte-histo-presentation p {
    display: flex;
    text-align: center;
    
}













.container-orga-presentation{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 700px;
    background-color: #ECECEC;
}

.presentation-orga-administratif {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    width: 400px;
    height: 620px;
}

.presentation-orga-sportif {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    width: 400px;
    height: 620px;
}

.titre-presentation-orga-administratif {
    display: flex;
    justify-content: center;
}

.titre-presentation-orga-administratif h2 {
    color: white;
    background-color: #2B334F;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.image-presentation-orga-administratif{
    display: flex;
    width: 400px;
    height: 500px;
    background-image: url("../../public/img/organigramme/organigramme-administratif.jpg");
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.titre-presentation-orga-sportif {
    display: flex;
    justify-content: center;
}

.titre-presentation-orga-sportif h2 {
    color: white;
    background-color: #2B334F;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.image-presentation-orga-sportif{
    display: flex;
    width: 400px;
    height: 500px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-image: url("../../public/img/organigramme/organigramme-sportif.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: conver;
}


@media (max-width: 916px) {
    .container-histo-presentation{
        height: 650px;
    }

    .texte-histo-presentation{
        font-size: 14px;
    }
}

@media (max-width: 799px) {
    .container-orga-presentation{
        height: 1400px;
    }

    .container-histo-presentation{
        height: 800px;
    }

    .texte-histo-presentation{
        font-size: 14px;
    }
}

@media (max-width: 670px) {
    .container-histo-presentation{
        height: 800px;
    }

    .texte-histo-presentation{
        font-size: 12px;
    }
}

@media (max-width: 440px) {
    .container-histo-presentation{
        height: 700px;
    }

    .texte-histo-presentation{
        font-size: 10px;
    }
}

@media (max-width: 400px) {
    .container-histo-presentation{
        height: 600px;
    }

    .texte-histo-presentation{
        font-size: 9px;
    }

    .container-orga-presentation{
        height: 1500px;
    }

    .image-presentation-orga-administratif{
        width: 270px;
    }

    .image-presentation-orga-sportif{
        width: 270px;
    }

    .titre-presentation-orga-administratif h2 {
        padding: 10px 2px 10px 2px;
    }

    .titre-histo-presentation h2 {
        padding: 10px 7px 10px 7px;
    }
}