*{
    padding: 0px;
    margin: 0px;
    font-family: Roboto, sans-serif;
}

.container-titre-contact-form {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    justify-content: space-around;
    height: 580px;
    background-color: #ECECEC;
}


.titre-contact-form {
    display: flex;
    justify-content: center;
}

.titre-contact-form h2 {
    color: white;
    display: flex;
    background-color: #2B334F;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    font-size: 32px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.container-info-contact {
    display: flex;
    height: 500px;
    width: 100%;
    background-color: #ECECEC;
}

.container-map-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    
}

.map-contact {
    display: flex;
    height: 400px;
    width: 400px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}




.container-item-contact {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.item-reseau-contact {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 30%;
    width: 100%;
}

.item-facebook-contact img{
    width: 70px;
}

.item-instagram-contact img{
    width: 70px;
}

.item-card-contact {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 70%;
    width: 100%;
}

.item-contact{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    background-color: #D9D9D9;
    border-radius: 15px;
    height: 80%;
    width: 30%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.logo-item-contact{
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 15%;
    width: 30%;
}

#logo-item-contact-adresse {
    background-image: url("../../public/img/ping.png");
}

#logo-item-contact-telephone {
    background-image: url("../../public/img/telephone.png");
}

#logo-item-contact-email {
    background-image: url("../../public/img/email.png");
}

.titre-item-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 100%;
}

.contenu-item-contact{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40%;
    width: 100%;
    text-align: center;
}


@media (max-width: 1185px) {
    .item-card-contact {
        flex-direction: column;
        height: 1000px;
    }

    .container-info-contact {
        flex-direction: column;
        height: 100%;
    }

    .container-map-contact {
        width: 100%;
        height: 400px;
    }

    .container-item-contact {
        width: 100%;
    }

    .item-reseau-contact{
        height: 140px;
    }

    .item-card-contact {
        height: 1100px;
    }

    .item-contact {
        height: 320px;
        width: 300px;
    }
}

@media (max-width: 410px) {
    .map-contact {
        height: 300px;
        width: 300px;
    }
}

