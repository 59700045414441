*{
    margin: 0px;
    padding: 0px;
}

.container-form-article {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.titre-form-article {
    display: flex;
    align-items: center;
    height: 10%;
}

.titre-form-article h2 {
    color: white;
    background-color: #000000;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.form-article {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 90%;
    flex-direction: column;
}

.container-form-article h2 {
    color: white;
}

.form-group label {
    color: white;
}

#titre-form-article {
    height: 10%;
    width: 100%;
    padding: 12px;
    outline: none;
    border: none;
    border-radius: 15px;
    font-size: 16px;
    color: rgba(15, 15, 51, 0.8);
    background-color: #6d6d6d;
    color: white;
}

#description-form-article {
    height: 100%;
    width: 100%;
    padding: 12px;
    outline: none;
    border: none;
    border-radius: 15px;
    font-size: 16px;
    color: rgba(15, 15, 51, 0.8);
    background-color: #6d6d6d;
    color: white;
}

#image-form-article{
    display: flex;
    color: white;
}


.container-form-article button{
    background-color: #EE7F12;
    border-radius: 15px;
    width: 50%;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    padding: 8px;
    transition: color 100ms;
    touch-action: manipulation;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
  
.container-form-article button:hover,
.container-form-article button:focus {
    background-color: #d5720e;
}

#connectez-panel{
    height: 100%;
    text-align: center;
}

#connectez-panel a{
    text-decoration: underline;
    color: white;
}

@media (min-width: 941px) {
  #connectez-panel {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 940px){
    .form-article{
        height: 400px;
        text-align: center;
    }
}