*{
    padding: 0px;
    margin: 0px;
    font-family: Roboto, sans-serif;
}

.container-image-equipes {
    display: flex;
    width: 70%;
    margin: 20px 0px 20px 0px;
    border: 1px solid black;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.equipes-image {
    height: 100%;
    width: 100%;
}

.container-info-equipes {
    display: flex;
    width: 100%;
    height: 700px;
}

.container-equipes-derniers-resultats {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
}

.titre-equipes-derniers-resultats {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
}

.titre-equipes-derniers-resultats h2{
    color: white;
    background-color: #2B334F;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.scorenco-equipes-derniers-resultats{
    display: flex;
    width: 400px;
    height: 500px;
    background-color: #D9D9D9;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}






.container-equipes-prochains-matchs {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
}

.titre-equipes-prochains-matchs {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
}

.titre-equipes-prochains-matchs h2{
    color: white;
    background-color: #2B334F;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.scorenco-equipes-prochains-matchs{
    display: flex;
    width: 400px;
    height: 500px;
    background-color: #D9D9D9;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}





.container-equipes-classement {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
}


.titre-equipes-classement {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
}

.titre-equipes-classement h2{
    color: white;
    background-color: #2B334F;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    font-size: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.scorenco-equipes-classement{
    display: flex;
    width: 400px;
    height: 500px;
    background-color: #D9D9D9;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}














@media (max-width: 1220px) {

    .container-info-equipes {
        flex-direction: column;
        height: 2000px;
    }

    .container-image-equipes {
        width: 80%;
    }
}



@media (max-width: 420px) {
    .equipes-prochains-matchs {
        width: 270px;
    }

    .scorenco-equipes-derniers-resultats {
        width: 280px;
    }

    .scorenco-equipes-prochains-matchs {
        width: 280px;  
    }

    .scorenco-equipes-classement {
        width: 280px;  
    }

    


}