*{
    margin: 0px;
    padding: 0px;
}

.container-card-partenaire{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px 20px 0px;
    border: 1px solid black;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: #D9D9D9;
    border-radius: 5px;
}

.container-card-partenaire img{
    display: flex;
    width: 200px;
}

.image-media1 {
    height: 90px;
}


@media (max-width: 645px) {
    .image-media1{
        height: 90px;
    }
}