*{
    margin: 0px;
    padding: 0px;
}

.container-form-photo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.titre-form-photo{
    display: flex;
    align-items: center;
    height: 10%;
    
}

.titre-form-photo h2 {
    color: white;
    background-color: #000000;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.form-photo {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 90%;
    flex-direction: column;
    background-color: antiquewhite;
}

.container-form-photo h2 {
    color: white;
}

.form-group label {
    color: white;
}

.image-form-photo {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
}


#image-form-photo{
    display: flex;
    color: white;
}


.container-form-photo button{
    background-color: #EE7F12;
    border-radius: 15px;
    width: 50%;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    padding: 8px;
    transition: color 100ms;
    touch-action: manipulation;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
  
.container-form-photo button:hover,
.container-form-photo button:focus {
    background-color: #d5720e;
}

@media (max-width: 940px){
    .container-form-photo{
        height: 200px;
        text-align: center;
    }
}