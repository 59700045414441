*{
    margin: 0px;
    padding: 0px;
    font-family: Roboto, sans-serif;
}

.container-panel {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #2B334F;
}

.container-titre-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30%;
}

.container-titre-panel h1 {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
}

.container-titre-panel img {
    height: 100px;
    display: flex;
}

.container-add {
    display: flex;
    height: 60%;
}

.container-add-article {
    display: flex;
    justify-content: center;
    width: 30%;
}

.container-photo-partenaires {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 70%;
}

.container-add-photo {
    display: flex;
    justify-content: center;
    width: 50%;
}


.container-add-partenaires {
    display: flex;
    justify-content: space-around;
    width: 50%;
    flex-direction: row;
}

.add-partenaires1{
    display: flex;
    text-align: center;
}

.add-partenaires2{
    display: flex;
    text-align: center;
}

.add-partenaires3{
    display: flex;
    text-align: center;
}

.container-bouton-panel {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 10%;
}

.logout-boutton-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-bouton-panel button{
    background-color: #EE7F12;
    border-radius: 15px;
    border-style: none;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    line-height: 20px;
    outline: none;
    padding: 7px 15px;
    transition: color 100ms;
    touch-action: manipulation;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
  
.container-bouton-panelbutton:hover,
.container-bouton-panel button:focus {
    background-color: #d5720e;
}

.text-logout-bouton {
    color: white;
}

.logout-boutton-panel :nth-child(1) { order: 2; }
.logout-boutton-panel :nth-child(2) { order: 1; }





@media (max-width: 1110px) {
    .container-add-partenaires {
        flex-direction: column;
    }

    .container-panel {
        height: 1300px;
    }

    .container-photo-partenaires {
        height: 800px;
    }

    .container-add-photo{
        width: 100%;
        width: 50%;
        align-items: center;
    }

    .container-add-partenaires {
        height: 1200px;
        width: 100%;
        align-items: center;
    }
}






@media (max-width: 940px) {
    .container-panel {
        height: 2200px;
    }

    .container-titre-panel {
        height: 30%;
    }

    .container-photo-partenaires {
        height: 900px;
    }

    .container-add-photo{
        width: 100%;
        align-items: center;
    }

    .container-add-partenaires {
        height: 1200px;
        width: 100%;
        align-items: center;
    }

    .container-add{
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .container-bouton-panel{
        flex-direction: column;
        height: 220px;
    }

    .logout-boutton-panel{
        flex-direction: column-reverse;
    }
    .container-bouton-panel :nth-child(1) { order: 1; }
    .container-bouton-panel :nth-child(2) { order: 3; }
    .container-bouton-panel :nth-child(3) { order: 2; }

}