*{
    padding: 0px;
    margin: 0px;
}

.map-resize {
    height: 400px;
    width: 400px;
}

@media (max-width: 410px) {
    .map-resize {
        height: 300px;
        width: 300px;
    }
}