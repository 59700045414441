*{
    margin: 0px;
    padding: 0px;
    font-family: Roboto, sans-serif;
}

.image-lastphoto {
    height: 540px;
    width: 958px;
}

#date-photo {
    font-style: italic;
}


@media (max-width: 980px) {
    .image-lastphoto {
        height: 360px;
        width: 638px;
    }
}

@media (max-width: 650px){
    .image-lastphoto {
        height: 270px;
        width: 478px;
    }
}

@media (max-width: 490px) {
    .image-lastphoto {
        height: 216px;
        width: 382px;
    }  
}

@media (max-width: 390px) {
    .image-lastphoto {
        height: 180px;
        width: 318px;
    }    
}

@media (max-width: 325px) {
    .image-lastphoto {
        height: 154px;
        width: 272px;
    }  
}
