*{
    margin: 0px;
    padding: 0px;
    font-family: Roboto, sans-serif;
}

.image-lastarticle {
    width: 400px;
    height: auto;
}

.container-lastarticle-item {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    height: 170px;
}

#date-article {
    font-style: italic;
}

@media (max-width: 400px) {
    .image-lastarticle {
        width: 270px;
    }

    .container-lastarticle-item {
        width: 270px;
    }
}