* {
    padding: 0px;
    margin: 0px;
    font-family: Roboto, sans-serif;
}

.container-partenaires {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #ECECEC;
}

.titre-partenaires {
    display: flex;
    padding: 60px 0px 25px 0px;
}

.container-partenaires h2{
    color: white;
    display: flex;
    background-color: #2B334F;
    padding: 10px 15px 10px 15px;
    border-radius: 5px;
    font-size: 32px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.item-partenaires {
    display: flex;
    width: 80%;
    justify-content: space-around;
}

.i1-partenaires {
    display: flex;
    justify-content: center;
    width: 30%;
}

.i2-partenaires {
    display: flex;
    justify-content: center;
    width: 30%;
}

.i3-partenaires {
    display: flex;
    justify-content: center;
    width: 30%;
}

@media (max-width: 1170px) {
    .item-partenaires {
        flex-direction: column;
        align-items: center;
    }
}