*{
    padding: 0px;
    margin: 0px;
}

.equipe-choice {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 100%;
    background-color: #ececec;
}

.image-equipe-choice {
    display: flex;
    background-image: url("../../../public/img/LogoUSCF.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 60%;
    width: 30%;
}

@media (max-width: 500px) {
    .equipe-choice {
        height: 200px;
    }
}